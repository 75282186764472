<template>
  <div class="about-us">
    <main class="relative flex flex-col justify-center overflow-hidden">
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="flex flex-col justify-center">
          <div class="w-full max-w-4xl mx-auto grid grid-cols-3 items-center">
            <div class="sm:px-4">
              <img class="w-4/5 mb-5 mx-auto" src="@/assets/img/app.jpg" />
            </div>
            <div class="w-full max-w-4xl mx-auto mb-4 col-span-2">
              <img class="w-4/5 mb-5" src="@/assets/img/nova_mais.png" />
              <h1 class="font-regular text-main-purple-500 text-2xl leading-6">
                Baixe o APP da Nova Mais e aproveite nossas
                <stong class="font-black">super vantagens</stong>!
              </h1>

              <div class="sm:grid sm:grid-cols-2 items-center my-4">
                <google-play-button />
                <app-store-button />
              </div>
            </div>
          </div>
          
        <box-nova-mais />

          <div class="w-full max-w-4xl mx-auto my-4">
            <youtube-embed embed-link="TvxzkKvFsHY" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BoxNovaMais from "@/components/layout/home/BoxNovaMais.vue";
import GooglePlayButton from "@/components/layout/goods/GooglePlayButton.vue";
import AppStoreButton from "@/components/layout/goods/AppStoreButton.vue";
import YoutubeEmbed from "@/components/layout/goods/YoutubeEmbed.vue";
export default {
  name: "App",
  components: {
    BoxNovaMais,
    GooglePlayButton,
    AppStoreButton,
    YoutubeEmbed,
  },
};
</script>

FaqAccordion
