<template>
  <Carousel :pictures="pictures" class="rounded"></Carousel>
</template>
>
<script setup>
import { Carousel } from "flowbite-vue";
const pictures = [
  {
    src: "./assets/img/carrossel/01.jpg",
    alt: "A Nova se transformou!",
  },
  {
    src: "./assets/img/carrossel/sva_01.jpg",
    alt: "Vamos deixar você ligado nos maiores sucessos do cinema com a HBO Max!",
  },
  {
    src: "./assets/img/carrossel/sva_02.jpg",
    alt: "Vá além com a nossa conexão! Ouça suas músicas favoritas com Deezer.",
    href: "#",
    link: "#",
    url: "#",
  },
  {
    src: "./assets/img/carrossel/03.jpg",
    alt: "Indique um amigo e ganhe 50% de desconto em uma mensalidade!",
  },
  {
    src: "./assets/img/carrossel/banner_5.jpg",
    alt: "A família Nova cresceu! Conheça a Ivy.",
  },
  {
    src: "./assets/img/carrossel/06.jpg",
    alt: "Modo ON DIVERSÃO ativado! Dê um play na diversão com a ultravelocidade da Nova.",
  },
];
</script>
