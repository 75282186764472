<template>
  <div class="w-full text-center p-4" :class="mainHeaderColor">
    <span
      class="mr-6 text-lg font-extrabold cursor-pointer"
      :class="mainHeaderFont"
      @click="this.chooseCityScreen()"
      >{{ getCityName() }} <BIconCaretLeftFill class="inline -rotate-90" /></span
    >
    <!-- <router-link :to="{name: 'index', params: {planType: 'voce'}}" class="mr-6 text-sm font-extrabold cursor-pointer">Você</router-link>
    <router-link :to="{name: 'index', params: {planType: 'empresa'}}" class="mr-6 text-sm font-extrabold cursor-pointer">Empresa</router-link> -->
    <span
      class="mr-6 text-lg font-extrabold cursor-pointer"
      :class="mainHeaderFont"
      @click="this.saveType(1)"
      >Para Você</span
    >
    <span
      class="text-lg font-extrabold mr-6 cursor-pointer"
      :class="mainHeaderFont"
      @click="this.saveType(2)"
      >Para Empresas</span
    >
    <span
      class="text-lg font-extrabold cursor-pointer"
      :class="mainHeaderFont"
      >
    <BIconTelephoneFill class="inline-block" />
      (31)3614-9300</span
    >
  </div>
  <div :class="mainMenuColor">
    <nav
      class="container px-2 py-4 mx-auto md:flex md:justify-between md:items-center"
    >
      <div class="flex items-center justify-between">
        <div>
          <router-link to="/" class="flex items-center">
            <img
              v-if="this.typeId == 2"
              src="@/assets/img/logo_nova_bco.png"
              height="10"
              class="h-12 md:h-14 mr-3 py-3"
              alt="Nova Telecom"
            />
            <img
              v-else
              src="@/assets/img/logo_nova.png"
              height="10"
              class="h-12 md:h-14 mr-3 py-3"
              alt="Nova Telecom"
            />
          </router-link>
        </div>
        <!-- Mobile menu button -->
        <div @click="toggleNav" class="flex md:hidden">
          <button
            type="button"
            class="text-main-purple-500 hover:text-main-purple-700 focus:outline-none focus:text-main-purple-700"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->

      <ul
        class="flex-col mt-8 space-y-2 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0 text-base font-bold text-main-purple-500"
        :class="showMenu ? 'flex' : 'hidden'"
      >
        <li>
          <router-link
            to="/sobre-nos"
            class="hover:font-bold md:p-4 py-2 block"
            :class="mainMenuFont"
            >Sobre nós</router-link
          >
        </li>
        <li>
          <router-link
            to="/planos"
            class="hover:font-bold md:p-4 py-2 block"
            :class="mainMenuFont"
            >Planos</router-link
          >
        </li>
        <li>
          <router-link
            to="/segunda-via"
            class="hover:font-bold md:p-4 py-2 block"
            :class="mainMenuFont"
            >Segunda Via</router-link
          >
        </li>
        <li>
          <router-link
            to="/faq"
            class="hover:font-bold md:p-4 py-2 block"
            :class="mainMenuFont"
            >FAQ</router-link
          >
        </li>
        <li>
          <router-link
            to="/ouvidoria"
            class="hover:font-bold md:p-4 py-2 block"
            :class="mainMenuFont"
            >Ouvidoria</router-link
          >
        </li>
        <li>
          <router-link
            to="/nova-mais"
            class="hover:font-bold md:p-4 py-2 block"
            :class="mainMenuFont"
            >Nova Mais</router-link
          >
        </li>
        <li>
          <a
            class="font-bold hover:font-black md:p-4 py-2 block hover:text-main-purple-300"
            :class="mainMenuFont"
            href="https://sistemarnova.com.br/sac/login/?sys=SAC"
            target="_blank"
            >Fazer login</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import CitySelection from "@/mixins/CitySelection";
import { ref } from "vue";
export default {
  setup() {
    let showMenu = ref(false);
    const toggleNav = () => (showMenu.value = !showMenu.value);
    return { showMenu, toggleNav };
  },
  props: {
    mainMenuFont: String,
    mainMenuColor: String,
    mainHeaderFont: String,
    mainHeaderColor: String
  },
  methods: {
    getCityName() {
      let varCity = parseInt($cookies.get("selectCity") )

      switch (varCity) {
        case 1:
          return "Belo Horizonte";
          break;
        case 2:
          return "Esmeraldas";
          break;
        case 3:
          return "Ribeirão das Neves";
          break;
        case 4:
          return "Sabará";
          break;
        case 5:
          return "Santa Luzia";
          break;
        case 6:
          return "Vespasiano";
          break;
        case 7:
          return "Justinópolis";
          break;
        
      }
    }    
  },
  data: () => ({
    menuColor: null,
    headerColor: null,
    headerFont: null,
  }),
  mixins: [CitySelection],
};
</script>