<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-8">
          <h1
            class="font-black text-main-purple-500 text-6xl text-center tracking-tighter my-4"
          >
            Fale conosco
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Envie-nos uma mensagem!
          </h3>
          <div
            class="p-6 border-2 rounded-xl my-4 bg-main-pink-500 text-white border-l-8 border-l-main-yellow-500"
          >
            <h3
              class="font-extrabold text-main-yellow-500 text-xl mb-2 text-center"
            >
              Obs: Caso seja assinante precisam ser informados os dados do
              titular da linha.
            </h3>
          </div>
        </div>

        <return-box
          :return-status="`${this.status}`"
          :return-title="`${this.responseTitle}`"
          >{{ this.responseBody }}</return-box
        >

        <form @submit.prevent="this.sendContact()">
          <div class="w-full max-w-4xl mx-auto grid sm:grid-cols-2 gap-1">
            <div class="col-span-2 p-2">
              <input
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu nome"
                name="clientName"
                id="clientName"
                v-model="clientName"
                required
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['###.###.###-##', '##.###.###/####-##']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu CPF ou CNPJ"
                name="clientDoc"
                id="clientDoc"
                v-model="clientDoc"
                required
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['(##)####-####', '(##)#####-####']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu telefone"
                name="clientPhone"
                id="clientPhone"
                v-model="clientPhone"
                required
              />
            </div>
            <div class="col-span-2 p-2">
              <textarea
                name="clientMessage"
                id="clientMessage"
                placeholder="Digite aqui sua mensagem"
                rows="4"
                class="w-full form-textarea font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                v-model="clientMessage"
                required
              ></textarea>
            </div>
            <div class="col-span-2 p-2">
              <captcha-box />
            </div>
            <div class="col-span-2 p-2">
              <button
                class="font-regular bg-main-purple-500 text-main-yellow-500 text-xl uppercase tracking-tight px-4 py-3 rounded-xl my-4 float-right"
              >
                Enviar <strong class="font-black">Mensagem</strong>
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import ContactForm from "@/mixins/ContactForm";
import CaptchaBox from "@/components/layout/goods/CaptchaBox.vue";

export default {
  name: "Contato",
  mixins: [ContactForm],
  components: {
    CaptchaBox
  },
  data: () => ({
    messageId: 2,
    titleSuccess: "Mensagem enviada com sucesso!",
    messageSuccess:
      "Agradecemos o contato. Em breve, nossa equipe entrará em contato com você.",
    titleError: "Ops, algo deu errado...",
    messageError:
      "Nosso sistema não conseguiu enviar a mensagem neste momento. Por favor, tente novamente.",
  }),
};
</script>
