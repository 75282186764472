<template>
  <div>
    <a
      target="_blank"
      href="tel:+553136149300"
      id="phone-button"
      style="
        position: fixed;
        bottom: 108px;
        right: 110px;
        width: 56px;
        z-index: 999;
      "
      ><img
        style="width: 100%"
        src="@/assets/img/phone_button.svg"
    /></a>
  </div>
</template>
