import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutUsView from "../views/static/AboutUsView.vue";
import BuyView from "../views/static/BuyView.vue";
import ContactView from "../views/static/ContactView.vue";
import ContractsRegulationsView from "../views/static/ContractsRegulationsView.vue";
import FaqView from "../views/static/FaqView.vue";
import IvyView from "../views/static/IvyView.vue";
import NovaMaisView from "../views/static/NovaMaisView.vue";
import OmbudsmanView from "../views/static/OmbudsmanView.vue";
import OurStoresView from "../views/static/OurStoresView.vue";
import PurchaseView from "../views/static/PurchaseView.vue";
import RenegotiationView from "../views/static/RenegotiationView.vue";
import SegundaViaView from "../views/static/SegundaViaView.vue";
import StorePlansView from "../views/static/StorePlansView.vue";
import AltPlansView from "../views/static/AltPlansView.vue";
import UpgradeView from "../views/static/UpgradeView.vue";
import WorkWithUsView from "../views/static/WorkWithUsView.vue";

import TesteAxiosView from "../views/static/TesteAxiosView.vue";
import TesteSwiperView from "../views/static/TesteSwiper.vue";
import TesteCarousel from "../components/Carousel.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/home",
    name: "homeCampaign",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/indice/:planType",
    name: "index",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/sobre-nos",
    name: "about us",
    component: AboutUsView,
    meta: {
      title: "Sobre nós",
    },
  },
  {
    path: "/assine-ja/:planId(\\d+)?",
    name: "Buy",
    component: BuyView,
    query: {
      planId: 1,
    },
    meta: {
      title: "Contratar",
    },
  },
  {
    path: "/contratos",
    name: "contratos",
    component: ContractsRegulationsView,
    meta: {
      title: "Contratos e Regulamentos",
    },
  },
  {
    path: "/carrossel",
    name: "carrossel",
    component: TesteCarousel,
    meta: {
      title: "Carrossel",
    },
  },
  {
    path: "/teste-axios",
    name: "teste-axios",
    component: TesteAxiosView,
    meta: {
      title: "Teste Axios",
    },
  },
  {
    path: "/fale-conosco",
    name: "Contact",
    component: ContactView,
    meta: {
      title: "Fale Conosco",
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FaqView,
    meta: {
      title: "FAQ - perguntas frequentes",
    },
  },
  {
    path: "/conheca-ivy",
    name: "Ivy",
    component: IvyView,
    meta: {
      title: "Conheça a Ivy!",
    },
  },
  {
    path: "/nossas-lojas",
    name: "Our Stores",
    component: OurStoresView,
    meta: {
      title: "Nossas lojas",
    },
  },
  {
    path: "/nova-mais",
    name: "Nova Mais",
    component: NovaMaisView,
    meta: {
      title: "Nova Mais",
    },
  },
  {
    path: "/negociacao",
    name: "Negociacao",
    component: RenegotiationView,
    meta: {
      title: "Negociação",
    },
  },
  {
    path: "/ouvidoria",
    name: "Ouvidoria",
    component: OmbudsmanView,
    meta: {
      title: "Ouvidoria",
    },
  },
  {
    path: "/planos",
    name: "Planos",
    component: StorePlansView,
    meta: {
      title: "Planos",
    },
  },
  {
    path: "/planos-alt",
    name: "Planos Alt",
    component: AltPlansView,
    meta: {
      title: "Planos Alt",
    },
  },
  {
    path: "/assinar",
    name: "Purchase",
    component: PurchaseView,
    meta: {
      title: "Assine Já!",
    },
  },
  {
    path: "/segunda-via",
    name: "Segunda Via",
    component: SegundaViaView,
    meta: {
      title: "Segunda Via",
    },
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    component: UpgradeView,
    meta: {
      title: "Upgrade",
    },
  },
  {
    path: "/teste-swiper",
    name: "Teste Swiper",
    component: TesteSwiperView,
    meta: {
      title: "Teste Swiper",
    },
  },
  {
    path: "/trabalhe-conosco",
    name: "Trabalhe conosco",
    component: WorkWithUsView,
    meta: {
      title: "Trabalhe Conosco",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// router.beforeEach((to, from, next) => {
//   document.title = "Nova Telecom - " + `${to.meta.title}`;
//   next();
// });
router.beforeEach((to, from, next) => {
  const titleFromParams = to.params?.pageTitle

  if (titleFromParams) {
    document.title = `${document.title} - ${titleFromParams}`
  } else {
    document.title = 'Nova Telecom - ' + to.meta?.title ?? 'Nova Telecom'
  }
  next();
})


export default router;
